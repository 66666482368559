import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import { NoResultOffplanProperties } from '../../queries/common_use_query';
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImg from "../../images/no-image.png"
import { registerInterestURL } from '../../site/urls';
import { getWhatsAppURL } from '../../site/utils';

const OffplanNoResult = (props) => {

    const[department, setDepartment] = useState('residential');

    useEffect(()=>{
        props.department && setDepartment(props.department);
    },[props.department]);

    const {loading:loading, error:error, data:noResultdata} = NoResultOffplanProperties(props.status, department, props.searchType);

    return (
        <React.Fragment>
            {
                noResultdata && noResultdata.offplans.length > 0 &&
                noResultdata.offplans.map((hit, index) => {

                    // property details url structure
                    let uriStr = "";
                    
                    if (hit.search_type === "sales" && hit.department === 'residential') {
                        uriStr = `off-plan-property-for-sale/`
                    } else if (hit.search_type === "lettings" && hit.department === 'residential') {
                        uriStr = `property-for-rent/`
                    }
                    // property details url structure

                    let processedImages = JSON.stringify({});
                    if (hit?.imagetransforms?.images_Transforms) {
                      processedImages = hit.imagetransforms.images_Transforms;
                    }

                    return (
                        <div className="property-card-wrapper off-plan">
                            <div className="property-card-img-zoom">
                                {
                                    hit?.images && hit?.images.length > 0 ?
                                    <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                                        <img src={hit?.images && hit?.images[0].url} alt={hit.display_address} />
                                    </Link>
                                    :
                                    <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                                        <img src={NoImg} alt="no image" />
                                    </Link>
                                }
                            </div>
                            <div className="property-card-text-wrapper">
                                {
                                    hit.display_address &&
                                    <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                                        <div className="price-details">{hit.display_address}</div>
                                    </Link>
                                }
                                {
                                    hit.area &&
                                    // <div className="location-details off-plan d-flex align-items-center">
                                    <div className="location-details off-plan d-flex">
                                        <i className="icon icon-map-pin"></i>
                                        <span>{hit.area}</span>
                                    </div>
                                }
                                <ul className="off-plan-list">
                                    {
                                        hit.price &&
                                        <li className="list-details">From {"AED"} {hit.price.toLocaleString()}</li>
                                    }
                                    {
                                        hit.title &&
                                        <li className="list-details">{hit.title}</li>
                                    }
                                    <li className="list-details">Q4 - 2024 Handover</li>
                                </ul>
                                <ul className="list-inline off-plan-contact-list d-flex align-items-center">
                                    <li className="list-inline-item">
                                        <Link to={`${registerInterestURL}?pid=${hit?.id}`} className="d-flex align-items-center">
                                            <i className="icon icon-team-email"></i>
                                            <span>Enquire</span>
                                        </Link>
                                    </li>
                                    {
                                        hit.crm_negotiator_id?.phone &&
                                        <li className="list-inline-item hovericon">
                                            <a href={`tel:${hit.crm_negotiator_id?.phone}`}><span>{hit.crm_negotiator_id?.phone}</span></a>
                                        </li>
                                    }
                                    {
                                        hit.crm_negotiator_id?.phone &&
                                        <li className="list-inline-item">
                                            <a href={getWhatsAppURL(hit.crm_negotiator_id?.phone)} target='_blank' className="d-flex align-items-center">
                                                <i className="icon icon-team-whatsapp"></i>
                                                <span>WhatsApp</span>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

export default OffplanNoResult